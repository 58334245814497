<template>
	<div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header pa-0">
        <h1>Worker Safety Guidelines</h1>
      </div>
      <div class="dashboard__container--body">
            <h2>Safety is our top priority, and we want to ensure the well-being of all our workers.</h2>
    <p>Please familiarize yourself with the following safety guidelines to recognize and address potentially unsafe work situations:</p>

    <ul>
        <li><strong>Personal Protective Equipment (PPE):</strong> Always wear the appropriate PPE, such as gloves, safety
            goggles, and masks, when handling chemicals, cleaning hazardous materials, or working in dusty environments.</li>
        <li><strong>Slip, Trip, and Fall Hazards:</strong> Keep work areas clean, dry, and free from clutter. Promptly clean
            up spills and report any hazards to supervisors. Use caution signs when necessary, especially during wet cleaning.</li>
        <li><strong>Ladder Safety:</strong> Inspect ladders before use, checking for stability and any defects. Always
            maintain three points of contact (two feet and one hand) while on a ladder. Do not overreach; reposition the ladder
            instead.</li>
        <li><strong>Heavy Lifting:</strong> Use proper lifting techniques, bending at the knees, and lifting with your legs
            rather than your back. Seek assistance or use mechanical aids for heavy or bulky objects when necessary.</li>
        <li><strong>Electrical Safety:</strong> Avoid using damaged or frayed electrical cords and report them immediately. Do
            not overload electrical outlets or extension cords. Never operate electrical equipment with wet hands.</li>
        <li><strong>Fire Safety:</strong> Be aware of the location of fire extinguishers and emergency exits. Report any fire
            hazards or malfunctioning fire safety equipment promptly. In case of fire, follow established evacuation procedures.</li>
        <li><strong>Working Alone:</strong> Inform a supervisor or colleague when working in isolated areas or during off-hours.
            Carry a communication device if available for emergency situations.</li>
        <li><strong>Biohazard and Infectious Material Handling:</strong> Use appropriate precautions when handling
            biohazardous waste or materials. Dispose of biohazardous waste in designated containers according to regulations.</li>
        <li><strong>Emergency Situations:</strong> Familiarize yourself with emergency procedures and evacuation routes. Report
            all accidents, injuries, or unsafe conditions immediately to a supervisor.</li>
        <li><strong>Safe Equipment Use:</strong> Operate janitorial equipment, such as vacuum cleaners and floor polishers,
            according to manufacturer's instructions. Keep equipment in good working condition and report any malfunctions.</li>
        <li><strong>Stress Ergonomics:</strong> Be mindful of your posture and take short breaks to avoid repetitive strain
            injuries. Adjust workstations and tools to promote ergonomic positioning.</li>
    </ul>

    <p>Remember, your safety is essential to us. If you have any questions or encounter any potentially unsafe situations,
        don't hesitate to reach out to your supervisor or your Jump Staffing Manager. We appreciate your dedication to
        maintaining a safe working environment for everyone.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'safety',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
  },
}
</script>